import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, key) => {
      return (_openBlock(), _createElementBlock("div", {
        onClick: ($event: any) => (_ctx.select(key)),
        class: _normalizeClass(['form-control','pointer','pretty-border',{'pretty-button': _ctx.modelValue === key}]),
        key: key
      }, _toDisplayString(option), 11, _hoisted_2))
    }), 128))
  ]))
}