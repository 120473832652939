import axios from "axios";
import DepartInfo from "@/api/DepartInfo";

export interface Race {
    all_places: string;
    arrival_date: string;
    arrival_station: string;
    arrival_time: string;
    carrier: string;
    child_ticket: string;
    com_sbor: string;
    departure_time: string;
    dispatch_station: string;
    free_places: string;
    full_ticket: string;
    innCarrier: string;
    luggage_ticket: string;
    model: string;
    pointOfArrival: string;
    pointOfDeparture: string;
    regPdp: string;
    sale: string;
    status: string;
}

export interface Transaction {
    id: string;
    result: string;
    transaction: string;
}

export interface RaceListPlaces {
    ID: Transaction;
    PLACES: {
        [key:number]: string
    }
}

export async function getStations(station: string){
    const params = new URLSearchParams();
    params.set('where_station',station);
    params.set('list_station','1');
    return (await axios.get(`https://xn--80aabfadmv0amnkgbvd1bn.xn--p1ai/ajax/tds.php?${params.toString()}`)).data;
}

export async function getRaces(station: string,date: Date,from: string): Promise<Race[] | {[key:string]:string}>{
    const params = new URLSearchParams();
    params.set("where_station",from);
    params.set("arrival",station);
    params.set("date",`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
    params.set("list_route","");
    return (await axios.get("https://xn--80aabfadmv0amnkgbvd1bn.xn--p1ai/ajax/tds.php?"+params.toString())).data;
}

export async function getListPlaces(departInfo: DepartInfo,race: Race){
    const params = new URLSearchParams();
    params.set("where_station",departInfo.fromStation);
    params.set("arrival",departInfo.station);
    params.set("date",departInfo.getDepartDate());
    params.set("time",race.departure_time);
    params.set("col",(departInfo.children + departInfo.adults).toString());
    params.set("ending_station",race.arrival_station);
    params.set("list_places","");
    return (await axios.get("https://xn--80aabfadmv0amnkgbvd1bn.xn--p1ai/ajax/tds.php?"+params.toString())).data;
}
let list_information: any | null = null;
export async function getListInformation(): Promise<any>{
    if(list_information === null){
        const params = new URLSearchParams();
        params.set("list_information","");
        list_information = (await axios.get("https://xn--80aabfadmv0amnkgbvd1bn.xn--p1ai/ajax/tds.php?"+params.toString())).data;
    }
    return list_information;
}

export async function book(transaction: Transaction,departInfo: DepartInfo,listPlaces: number[],email: string,name: string,phone: string){
    const body = {
        transaction: transaction.transaction,
        id: transaction.id,
        where_station: departInfo.fromStation,
        booking: "true",
        col: departInfo.departures.length,
        b_email: email,
        b_name: name,
        b_phone: phone,
    } as {[key:string]: string | number}

    for(let i = 1;i <= departInfo.departures.length;i++){
        const departure = departInfo.departures[i-1];
        const date = departure.birthdate;
        body[`sn${i}`] = departure.surname;
        body[`fn${i}`] = departure.name;
        body[`ln${i}`] = departure.patronymic;
        body[`dt${i}`] = departure.document_type;
        body[`ds${i}`] = departure.document_series;
        body[`dn${i}`] = departure.document_number;
        body[`dr${i}`] = `${date.getDate()}.${(date.getMonth()+1).toString().padStart(2,"0")}.${date.getFullYear()}`;
        body[`gr${i}`] = departure.citizenship;
        body[`pp${i}`] = +departure.gender;
        body[`tb${i}`] = +departure.child_ticket;
        body[`places${i}`] = listPlaces[i-1];
    }

    return (await axios.post(
        "https://xn--80aabfadmv0amnkgbvd1bn.xn--p1ai/ajax/order/make/",
        body
    )).data;
}