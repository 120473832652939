
import { Options, Vue } from 'vue-class-component';
import {getListPlaces, Race, RaceListPlaces} from "@/api/Races";
import {Prop} from "vue-property-decorator";
import DepartInfo from "@/api/DepartInfo";

@Options({
  components: {

  }
})
export default class PlacePicker extends Vue {
  @Prop({required: true})
  places!: number[];
  @Prop({default: 999})
  max!: number;
  @Prop({default: 999})
  modelValue!: number[];
  checked: number[] = [];

  created(){
    this.checked = this.modelValue;
  }

  check(index: number){
    if(this.checked.includes(index)){
      return;
    }
    if(this.checked.length + 1 > this.max){
      this.checked.shift();
    }
    this.checked.push(index)

    this.$emit("update:modelValue",this.checked);
  }
}
