import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = { class: "form-control text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.decrementValue && _ctx.decrementValue(...args))),
      class: "form-control shadow-none"
    }, " - "),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.value), 1),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.incrementValue && _ctx.incrementValue(...args))),
      class: "form-control shadow-none"
    }, " + ")
  ]))
}