
import { Options, Vue } from 'vue-class-component';
import {Prop} from "vue-property-decorator";
import NumberPicker from "@/components/ui/NumberPicker.vue";

@Options({
  components: {
    NumberPicker,
  }
})
export default class HumanPicker extends Vue {
  @Prop({required: true})
  modelValue!: {adults:number,children: number}
  dropped = false;

  mounted(){
    document.addEventListener('click',this.onDocumentClick);
  }
  beforeUnmount(){
    document.removeEventListener('click',this.onDocumentClick)
  }

  updateAdults(count: number){
    this.$emit("update:modelValue",{
      adults: count,
      children: this.modelValue.children
    });
  }

  updateChildren(count: number){
    this.$emit("update:modelValue",{
      adults: this.modelValue.adults,
      children: count,
    });
  }

  showDropdown(){
    this.dropped = true;
  }

  onDocumentClick(e: MouseEvent){
    if(!e.composedPath().includes(this.$refs.wrapper as EventTarget)){
      this.hideDropdown();
    }
  }

  hideDropdown(){
    this.dropped = false;
  }
}
