import { vModelText as _vModelText, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["placeholder"]
const _hoisted_2 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return (_ctx.mask)
    ? _withDirectives((_openBlock(), _createElementBlock("input", {
        key: 0,
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        onFocusin: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocused && _ctx.onFocused(...args))),
        onFocusout: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFocusout && _ctx.onFocusout(...args))),
        onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        class: _normalizeClass(['form-control','shadow-none',{'is-invalid': _ctx.is_invalid}]),
        placeholder: _ctx.placeholder
      }, null, 42, _hoisted_1)), [
        [_vModelText, _ctx.value],
        [_directive_maska, _ctx.mask]
      ])
    : _withDirectives((_openBlock(), _createElementBlock("input", {
        key: 1,
        type: "text",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value) = $event)),
        onFocusin: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onFocused && _ctx.onFocused(...args))),
        onFocusout: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onFocusout && _ctx.onFocusout(...args))),
        onInput: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        class: _normalizeClass(['form-control','shadow-none',{'is-invalid': _ctx.is_invalid}]),
        placeholder: _ctx.placeholder
      }, null, 42, _hoisted_2)), [
        [_vModelText, _ctx.value]
      ])
}