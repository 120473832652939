import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-100 bg-gray-pulse rounded-2",
  style: {"height":"38px"}
}
const _hoisted_2 = {
  key: 1,
  class: "w-100 dropdown",
  ref: "wrapper"
}
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.options === null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDropdown())),
            class: _normalizeClass(['rounded-top', 'p-2', 'border', 'w-100',{'rounded-bottom': !_ctx.dropped}]),
            type: "text",
            placeholder: _ctx.placeholder
          }, null, 10, _hoisted_3), [
            [_vModelText, _ctx.filter]
          ])
        ]),
        _createElementVNode("div", {
          class: "dropdown-menu w-100 border rounded-0 rounded-bottom overflow-auto",
          style: _normalizeStyle({'display': _ctx.dropped ? 'block' : 'none','max-height':'350px'})
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions(), (option, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              onClick: ($event: any) => (_ctx.select(key)),
              class: "dropdown-item"
            }, _toDisplayString(option), 9, _hoisted_4))
          }), 128))
        ], 4)
      ], 512))
}