
import {Options, Vue} from 'vue-class-component';
import UiSelect from "@/components/ui/UiSelect.vue";
import Datepicker from '@vuepic/vue-datepicker';
import HumanPicker from "@/components/ui/HumanPicker.vue";
import {getStations} from "@/api/Races";
import DepartInfo from "@/api/DepartInfo";
import {Prop, Watch} from "vue-property-decorator";
import Month from "@/helpers/Month";

@Options({
  components: {
    UiSelect,
    Datepicker,
    HumanPicker,
  }
})
export default class WelcomeForm extends Vue {
  from_options: string[] = [];

  @Prop({required: true})
  departInfo!: DepartInfo;

  humans = {
    adults: 1,
    children: 0,
  }

  async mounted() {
    this.humans.adults = this.departInfo.adults
    this.humans.children = this.departInfo.children
    this.from_options = await getStations(this.departInfo.fromStation);
  }

  get fromStation() {
    return this.departInfo.fromStation === 'Абдулино' ? 'Абдулино' : 'Оренбург';
  }

  formatDate(date: Date) {
    return date.getDate() + ' ' + new Month(date.getUTCMonth()).getRuMultiple() + ' ' + date.getFullYear() + 'г.'
  }

  submit() {
    this.$emit("submit", {
      "arrive": this.departInfo.station,
      "when": this.departInfo.depart_date,
      "adults": this.humans.adults,
      "children": this.humans.children
    })
  }

  @Watch('departInfo.fromStation')
  async onDepartInfoChange(){
    this.from_options = [];
    this.from_options = await getStations(this.departInfo.fromStation);
  }
}
