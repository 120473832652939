
import { Options, Vue } from 'vue-class-component';
import {Prop} from "vue-property-decorator";
import DepartInfo from "@/api/DepartInfo";
import PlacePicker from "@/components/ui/PlacePicker.vue";
import DatePicker from '@vuepic/vue-datepicker';
import {getListInformation, Race} from "@/api/Races";
import UiSelect from "@/components/ui/UiSelect.vue";
import InlineSelect from "@/components/ui/InlineSelect.vue";
import RequiredInput from "@/components/ui/RequiredInput.vue";
import DeparturePerson from "@/api/DeparturePerson";

@Options({
  components: {
    PlacePicker,
    DatePicker,
    UiSelect,
    InlineSelect,
    RequiredInput,
  },
  emits: {
    "abort": null,
    "submit": null
  }
})
export default class DeparturesForm extends Vue {
  @Prop({required: true})
  departInfo!: DepartInfo;

  documents: string[] | null = null;
  citizenship: string[] | null = null;

  name = "";
  email = "";
  phone = "";

  async beforeCreate(){
    getListInformation().then(result=>{
      this.documents = result.DOCUMENTS as string[];
      this.citizenship = result.COUNTRY as string[];
    });
  }

  format(date: Date){
    return `${date.getDate()}.${(date.getMonth()+1).toString().padStart(2,"0")}.${date.getFullYear()}`
  }

  submit(){
    for(let i = 0;i < this.departInfo.departures.length;i++){
      const ticket = this.departInfo.departures[i];
      if(!ticket.filled())
      {
        return this.$emit("abort",`Не все поля билета ${i+1} заполнены`);
      }
    }
    this.$emit("submit",{
      name: this.name,
      email: this.email,
      phone: this.phone
    })
  }

  getDocumentSeriesMask(departure: DeparturePerson){
    if(departure.document_type === "Паспорт РФ"){
      return "####"
    }
    if(departure.document_type === "Св. о рождении"){
      return null;
    }
    return "#######################";
  }
  getDocumentSeriesPlaceholder(departure: DeparturePerson){
    if(departure.document_type === "Св. о рождении"){
      return "V-MЮ"
    }
    return "";
  }
  getDocumentNumberMask(departure: DeparturePerson){
    if(departure.document_type === "Паспорт РФ"){
      return "######"
    }
    if(departure.document_type === "Св. о рождении"){
      return "######"
    }
    return "#######################";
  }

  maskBirthCertificate(value: string){
    const parts = value.split('-');
    parts[0] = parts[0].replaceAll(/[^IVXLC]/g,"");
    if(parts[1]){
      parts[1] = parts[1].replaceAll(/[^А-Я]/g,"").slice(0,2);
    }
    return parts.join("-");
  }

  clearWhitespaces(value: string){

    return value.replaceAll(" ","");
  }
}
