
import { Options, Vue } from 'vue-class-component';
import {Prop} from "vue-property-decorator";

@Options({

})
export default class InlineSelect extends Vue {
  @Prop({required: true})
  options!: {[key:string]: string};
  @Prop({default: false})
  returnValue!: boolean;
  @Prop({required: true})
  modelValue!: string;

  select(key: string){
    let out = key;
    if(this.returnValue){
      out = this.options[key];
    }
    this.$emit("update:modelValue",out);
    this.$emit("input",out);
  }
}
