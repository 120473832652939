
import { Options, Vue } from 'vue-class-component';
import {Prop} from "vue-property-decorator";

@Options({

})
export default class UiSelect extends Vue {
  @Prop({required: true})
  options!: {[key:string]: string};
  @Prop({default: ""})
  placeholder!: string;
  @Prop({default: false})
  returnValue!: boolean;
  @Prop({required: true})
  modelValue!: string;

  dropped = false;
  filter = "";

  filteredOptions(){
    let out: {[key:string]:string} = {};
    for(let key in this.options){

      if(this.options[key].toLocaleLowerCase().startsWith(this.filter.toLocaleLowerCase())){
        out[key] = this.options[key];
      }
    }
    return out;
  }

  mounted(){
    this.filter = this.modelValue;
    document.addEventListener('mousedown',this.onDocumentClick);
  }
  beforeUnmount(){
    document.removeEventListener('mousedown',this.onDocumentClick)
  }

  showDropdown(){
    this.dropped = true;
  }

  onDocumentClick(e: MouseEvent){
    if(!e.composedPath().includes(this.$refs.wrapper as EventTarget)){
      this.hideDropdown();
    }
  }

  hideDropdown(){
    setTimeout(()=>{
      this.dropped = false;
      if(this.options && !Object.values(this.options).includes(this.filter)){
        this.filter = this.modelValue;
      }
    },10)
  }

  select(key: string){
    this.hideDropdown();

    this.filter = this.options[key];
    let out = key;
    if(this.returnValue){
      out = this.options[key];
    }
    this.$emit("update:modelValue",out);
    this.$emit("input",out);
  }
}
