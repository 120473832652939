import {Race} from "@/api/Races";
import DeparturePerson from "@/api/DeparturePerson";

export default class DepartInfo {
    fromStation = "Оренбург междугородный АВ";
    adults = 1;
    children = 0;
    station = "";
    depart_date = new Date();
    races: Race[] = [];
    departures: DeparturePerson[] = [];

    setPlaces(adults: number,children: number){
        this.adults = adults;
        this.children = children;
        return this;
    }
    setDepartDate(date: Date){
        this.depart_date = date;
        return this;
    }
    setDepartPlace(from: string){
        this.station = from;
    }
    addRace(race: Race){
        this.races.push(race);
    }

    getDepartDate():string {
        return `${this.depart_date.getFullYear()}-${this.depart_date.getMonth() + 1}-${this.depart_date.getDate()}`
    }

    addDeparture(person: DeparturePerson){
        this.departures.push(person);
    }
}