export default class Month{
    private index: number;

    constructor(index: number) {
        this.index = index;
    }

    getRuMultiple(){
        return [
            "января","февравля","марта","апреля","мая","июня","июля","августа",
            "сентября","октября","ноября","декабря"
        ][this.index];
    }
}