export default class DeparturePerson {
    name = "";
    surname = "";
    patronymic = "";
    gender = -1;
    document_series = "";
    document_number = "";
    birthdate = new Date();
    child_ticket = false;
    document_type = "";
    citizenship = "";

    setName(name: string){
        this.name =  name;
        return this;
    }

    setSurname(surname: string){
        this.surname = surname;
        return this;
    }

    setGender(gender: number){
        this.gender = gender;
        return this;
    }

    setBirthDate(date: Date){
        this.birthdate = date;
        return this;
    }

    childTicket(){
        this.child_ticket = true;
        return this;
    }

    filled(): boolean {
        return this.name !== "" && this.gender !== -1 && this.surname !== ""
            && this.patronymic !== "" && this.document_type !== "" && this.document_series !== ""
            && this.document_number !== ""
            ;
    }
}