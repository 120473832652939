
import { Options, Vue } from 'vue-class-component';
import {getListPlaces, Race, RaceListPlaces} from "@/api/Races";
import {Prop} from "vue-property-decorator";
import DepartInfo from "@/api/DepartInfo";
import PlacePicker from "@/components/ui/PlacePicker.vue";
import Month from "@/helpers/Month";

@Options({
  components: {
    PlacePicker
  }
})
export default class RacesForm extends Vue {
  @Prop({required: true})
  departInfo!: DepartInfo;
  @Prop({required: true})
  races!: Race[];

  occupiedPlaces: number[] = [];
  listPlaces: {[key: number]: RaceListPlaces} = {}
  selected_race: Race | null = null;

  sortedRaces(){
    return this.races.sort((a,b)=>{
      return this.getTotalPrice(a) - this.getTotalPrice(b)
    })
  }

  getTotalPrice(race: Race): number{
    return this.getAdultsPrice(race) + this.getChildrenPrice(race) + this.getCommissionFee(race);
  }

  getCommissionFee(race: Race): number{
    return +race.com_sbor * (+this.departInfo.children + +this.departInfo.adults)
  }

  getChildrenPrice(race: Race){
    return (+race.child_ticket) * this.departInfo.children;
  }

  getAdultsPrice(race: Race){
    return (+race.full_ticket) * this.departInfo.adults;
  }

  getArrivalDate(date: string | Date): string{
    if(date instanceof Date){
      return date.getDate() + " " + new Month(date.getMonth()).getRuMultiple();
    }else{
      const dates = date.split(".");
      return +dates[0] + ' ' + new Month(+dates[1] - 1).getRuMultiple();
    }
  }

  selectRace(race: Race){
    if(race === this.selected_race){
      this.selected_race = null;
    }else{
      this.selected_race = race;
      getListPlaces(this.departInfo,race).then(data=>{
        this.listPlaces[this.races.indexOf(race)] = data;
      })
    }
  }

  submit(){
    this.$emit("submit",{
      places: this.occupiedPlaces,
      race: this.selected_race,
      departInfo: this.departInfo,
      transaction: this.listPlaces[this.races.indexOf(this.selected_race!)].ID
    });
  }
}
