
import { Options, Vue } from 'vue-class-component';
import {Prop} from "vue-property-decorator";


@Options({
  components: {

  }
})
export default class OrderViewer extends Vue {
  @Prop({required: true})
  link!: string;
  @Prop({default: ""})
  form!: string;

  timer = 10;
  timerInterval = 0;

  mounted(){
    this.timerInterval = setInterval(()=>{
      if(this.timer === 0) {
        clearInterval(this.timerInterval)
        this.timerInterval = 0;
      }else {
        this.timer -= 1;
      }
    }, 1000 * 60)
  }

  beforeUnmount(){
    clearInterval(this.timerInterval);
  }
}
