
import { Options, Vue } from 'vue-class-component';
import {Prop} from "vue-property-decorator";

@Options({
  components: {

  }
})
export default class RequiredInput extends Vue {
  @Prop()
  modelValue!: string;
  @Prop()
  mask: any;
  @Prop({default: ""})
  placeholder!: string;
  @Prop({default: null})
  mutator!: ((value: string) => string) | null;
  is_invalid = false;
  value = "";

  onFocused(){
    this.is_invalid = false;
  }

  onFocusout(){
    this.is_invalid = this.modelValue.length <= 0;
  }

  onInput(e: InputEvent){
    let value = this.value;
    if(this.mutator){
      value = this.mutator(value);
    }

    this.value = value;
    this.$emit("update:modelValue",value);
  }
}
