
import { Options, Vue } from 'vue-class-component';
import WelcomeForm from "@/components/WelcomeForm.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/ui.scss'
import {book, getRaces, Race, Transaction} from "@/api/Races";
import RacesForm from "@/components/RacesForm.vue";
import DepartInfo from "@/api/DepartInfo";
import DeparturePerson from "@/api/DeparturePerson";
import DeparturesForm from "@/components/DeparturesForm.vue";
import OrderViewer from "@/components/OrderViewer.vue";

@Options({
  components: {
    WelcomeForm,
    RacesForm,
    DeparturesForm,
    OrderViewer,
  },
})
export default class App extends Vue {
  stage = 1;
  message = "";

  departInfo = new DepartInfo();
  races: Race[] | null = null;
  showLoading = false;
  transaction: Transaction | null = null;
  occupied_places: number[] = [];

  pay_link = "";

  abort(message: string){
    this.message = message;
    this.showLoading = false;
  }
  hideMessage(){
    this.message = "";
  }

  async onFormSubmit(data: {arrive:string,when: Date,adults: number,children: number}){
    this.hideMessage();
    this.showLoading = true;

    if(!data.arrive){
      return this.abort("Выберите пункт назначения")
    }
    if(!data.when){
      return this.abort("Выберите дату")
    }
    if(data.adults + data.children <= 0){
      return this.abort("Укажите не менее одного пассажира")
    }

    this.departInfo
        .setPlaces(data.adults,data.children)
        .setDepartDate(data.when)
        .setDepartPlace(data.arrive);

    const races = await getRaces(this.departInfo.station,this.departInfo.depart_date,this.departInfo.fromStation);
    if(races instanceof Array && races.length > 0){
      this.races = [];
      for (let race of races){
        this.races.push(race);
      }
      this.passStage();
    }else{
      return this.abort("Не удалось найти ни одного рейса. Попробуйте указать другое число");
    }
    this.showLoading = false;
  }

  async onRaceSubmit(data: {places: number[],race: Race,departInfo: DepartInfo, transaction: Transaction}){
    let {places,departInfo} = data;
    this.transaction = data.transaction;
    this.hideMessage();

    if(places.length !== departInfo.children + departInfo.adults){
      return this.abort("Вы выбрали не все места. Необходимо выбрать еще " + (departInfo.children + departInfo.adults - places.length) + ' места');
    }

    this.occupied_places = data.places;
    departInfo.departures = [];

    for (let i = 0;i < departInfo.adults;i++){
      departInfo.addDeparture(new DeparturePerson());
    }

    for (let i = 0;i < departInfo.children;i++){
      departInfo.addDeparture(new DeparturePerson().childTicket());
    }

    this.passStage();
  }

  async onDepartSubmit(user: {email:string,name: string,phone: string}){
    try {
      const response = await book(this.transaction!,this.departInfo,this.occupied_places,user.email,user.name,user.phone);
      this.pay_link = response.pay_link;
      this.passStage();
    }catch (e: any){
      this.abort(e.response.data.message);
    }

  }

  passStage(forward = true){
    this.hideMessage();
    if(this.stage >= 3 && !forward){
      this.stage = 1;
      return;
    }
    this.stage = Math.max(this.stage + (forward ? 1 : -1),1);
  }
}
