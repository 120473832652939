
import { Options, Vue } from 'vue-class-component';
import {Prop} from "vue-property-decorator";

@Options({

})
export default class NumberPicker extends Vue {
  @Prop({required: true})
  value!: number;

  @Prop({default: 0})
  min!: number;

  incrementValue(){
    this.updateValue(this.value + 1);
  }

  decrementValue(){
    this.updateValue(Math.max(this.value - 1,this.min));
  }

  updateValue(value: number){
    this.$emit("input",value);
  }
}
